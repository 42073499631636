import React, { useState } from "react"
import { Container } from "."
import { useLocation } from "@reach/router"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image-es5"
import useMainSiteUrl from "../mainSiteUrl"

const Footer = () => {
  const {
    fileName: {
      childImageSharp: { fluid: imageData },
    },
  } = useStaticQuery(graphql`
    query FooterImageQuery {
      fileName: file(relativePath: { eq: "overlay.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { pathname } = useLocation()
  const mainSiteUrl = useMainSiteUrl()
  return (
    <>
      <BackgroundImage
        Tag="div"
        fluid={imageData}
        style={{ marginTop: "-4vw", zIndex: "1" }}
        className="bg-yellow pt-8"
      >
        <div>
          <Container>
            <div className="text-center text-sm">
              {pathname !== "/" && (
                <h3>
                  <Link to="/"> Tillbaka till startsidan!</Link>
                </h3>
              )}
              <a href={mainSiteUrl}>
                Klicka här för att komma till partiets vanliga hemsida<br></br>{" "}
                alternativforsverige.se
              </a>
              <div className="flex flex-col items-center mt-10">
                © Alternativ för Sverige {new Date().getFullYear()}
              </div>
            </div>
          </Container>
        </div>
        <a
          className="absolute -bottom-2 right-0 pb-0 mb-0"
          href="https://www.svenskakyrkan.se/kyrkoval"
        >
          <StaticImage
            src="../../../assets/images/KV25_signalelement_bage_valdag_right.png"
            className="w-24"
          />
        </a>
      </BackgroundImage>
    </>
  )
}

export default Footer
